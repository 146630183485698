import { mapGetters } from 'vuex';
// import _ from 'lodash';
import store from '../store';
import { validateEmail } from '../utils/validation';

export default {
  computed: {
    ...mapGetters([
      'errors',
      'operatingCountry',
      'planCode',
      'accountTypeIndividual',
      'hardwareSelected',
      'horizonSize',
      'horizonTint',
      'firstName',
      'lastName',
      'address1',
      'city',
      'state',
      'country',
      'zip',
      'cc',
      'email',
      'isManualPayment',
    ]),
    // orderProperties() {
    //   // eslint-disable-next-line
    //   return [this.operatingCountry, this.planCode, this.hardwareSelected, this.horizonSize, this.horizonTint, this.firstName, this.lastName, this.email, this.address1, this.city, this.state, this.zip, this.cc, this.isManualPayment].join();
    // },
  },
  // watch: {
  //   orderProperties: {
  //     deep: true,
  //     // eslint-disable-next-line
  //     handler: _.debounce(function () {
  //       this.update();
  //     }, 2000),
  //   },
  // },
  methods: {
    update() {
      if (!this.accountTypeIndividual) return;
      if (!this.address1 || !this.city || !this.zip || !this.cc || (this.country === 'GB' && !this.state)) return;
      if (!validateEmail(this.email)) return;
      if (this.planCode && (this.hardwareSelected === 'none' || this.hardwareSelected === 'US-NFB-001' || (this.hardwareSelected !== 'US-NFB-001' && this.hardwareSelected !== 'none' && this.horizonSize && this.horizonTint)) && this.firstName && this.lastName && this.email) {
        if (this.errors.length) store.commit('CLEAR_ERRORS');
        store.dispatch('getOrderPreview');
      }
    },
  },
};
