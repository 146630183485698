<template>
  <div
    v-if="hasPageAccess(pageName)"
    id="internal-signup"
  >
    <h2 aria-label="Internal Sign Up">
      Internal Signup
    </h2>
    <b-form
      ref="signupForm"
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- ===== SALES ATTRIBUTION ===== -->
        <FormSection
          title="Sales Attribution"
        >
          <FormGroup
            for="salesAttribution"
            label="Initials of first and last name"
            class="w-100"
          >
            <FormInput
              bind-id="salesAttribution"
              get="salesAttribution"
              set="UPDATE_SALES_ATTRIBUTION"
            />
          </FormGroup>
        </FormSection>
        <!-- ===== NO PHONE ===== -->
        <FormSection
          v-if="showNoPhone"
          title="No Phone Number"
          description="For Business trying to purchase hardware."
        >
          <FormInput
            v-if="showNoPhone"
            bind-id="showNoPhone"
            type="checkbox"
            label="No Phone Number (DO NOT ABUSE! I AM WATCHING!!)"
            get="noPhone"
            set="TOGGLE_NO_PHONE"
            class="w-100"
          />
        </FormSection>
        <!-- ===== PHONE VERIFICATION ===== -->
        <FormSection
          v-if="showPhoneVerification && !noPhone"
          :description="`Check to see the status of the user. ${phone ? '[' + phone + ']' : ''}`"
          title="Phone Verification"
        >
          <div class="d-inline-flex justify-content-between form-inline-input">
            <FormGroup
              for="countryCode"
              label="Country Code"
              class="w-25-35"
            >
              <FormSelect
                :options="countryCodeOptions"
                bind-id="countryCode"
                default="+1"
                get="countryCode"
                set="UPDATE_COUNTRY_CODE"
              />
            </FormGroup>
            <FormGroup
              :state="phoneVerifyStatus"
              for="phoneNumber"
              label="Phone Number"
              invalid-feedback="Please re-verify the phone number."
              valid-feedback="Phone number is verified!"
              class="w-75-65"
            >
              <FormInput
                :state="phoneVerifyStatus"
                :disabled="phoneVerifyButtonDisabled"
                bind-id="phoneNumber"
                type="tel"
                placeholder="Mobile Number"
                get="phoneNumber"
                set="UPDATE_PHONE_NUMBER"
                @keyup.enter.native="verifyPhone"
              />
            </FormGroup>
          </div>
          <b-button
            id="phone-verify-button"
            :disabled="phoneVerifyButtonDisabled || phoneVerified"
            variant="primary"
            class="form-inline-button"
            @click="verifyPhone"
          >
            Check
          </b-button>
          <div
            v-if="phoneVerified"
            role="alert"
          >
            <!-- TODO link to corresponding env, with userId populated -->
            <p v-if="isGuest && (subscriptions || businessAccounts)">
              This is an <span class="emphasize">EXISTING USER</span>.
            </p>
            <p v-else-if="isGuest">
              This is a <span class="emphasize">GUEST</span>.
            </p>
            <p v-else>
              This is a <span class="emphasize">BRAND NEW USER</span>.
            </p>
            <p v-if="subscriptions">
              They are currently subscribed to the <b>{{ subscriptions.plan.name }}</b> plan.
              If you would like to modify subscription, please do so via
              <span><b-link
                :href="userMgmtLink"
                target="_blank"
              >
                mgmt dashboard.
              </b-link></span>
            </p>
            <p v-else>
              They are currently not subscribed to a plan.
            </p>
            <div v-if="businessAccounts">
              <p>They are currently associated with payment profiles:</p>
              <ul
                v-if="businessAccounts"
                id="plan-info"
              >
                <li
                  v-for="(account, index) in businessAccounts"
                  :key="index"
                >
                  {{ account.name }}
                </li>
              </ul>
            </div>
            <p v-else>
              They are not associated with any payment profiles.
            </p>
          </div>
        </FormSection>
        <!-- ===== COUNTRY ===== -->
        <FormSection
          v-if="showCountry"
          title="Operating Country"
          description="This determines the currency which the explorers are paying in."
          label="operatingCountry"
          for="operatingCountry"
          class="w-100"
        >
          <FormSelect
            :options="operatingCountryOptions"
            bind-id="operatingCountry"
            aria-label="operatingCountry"
            get="operatingCountry"
            set="UPDATE_OPERATING_COUNTRY"
          />
        </FormSection>
        <!-- ===== CREATE ===== -->
        <FormSection
          v-if="showNewSubscription"
          title="Create New Subscription?"
        >
          <FormInput
            bind-id="accountTypeIndividual"
            type="checkbox"
            label="Yes"
            get="accountTypeIndividual"
            set="TOGGLE_ACCOUNT_TYPE_INDIVIDUAL"
            class="w-100"
          />
        </FormSection>
        <!-- ===== PLAN ===== -->
        <FormSection
          v-if="showPlan"
          title="Plan"
        >
          <FormSelect
            :options="planOptions"
            bind-id="planCode"
            aria-label="plan"
            get="planCode"
            set="SET_PLAN_CODE"
            required
          />
          <ul
            v-if="planCode"
            id="plan-info"
          >
            <!-- eslint-disable -->
            <li
              v-for="(info, index) in plan.info"
              v-if="info"
              :key="index">
              {{ info }}
            </li>
            <!-- eslint-enable -->
          </ul>
        </FormSection>
        <!-- HARDWARE KIT SECTION -->
        <FormSection
          v-if="showHardware"
          title="Horizon Kit"
          description="Add the Horizon Kit to your plan! Choose between two payment options. Please note all payments made are non-refundable.<br>International shipping costs may apply."
        >
          <FormRadio
            :options="hardwareOptions"
            get="hardwareSelected"
            set="SET_HARDWARE_SELECTED"
          />
        </FormSection>
        <!-- HARDWARE KIT PREFERENCES SECTION -->
        <FormSection
          v-if="showHardwarePreferences"
          :title="hardwareSelectedInfo.name + ' Preferences'"
        >
          <div
            v-for="preference in hardwarePreferences"
            :key="preference.get"
          >
            <FormSection
              :description="preference.description"
            >
              <FormGroup
                :for="preference.get"
                class="w-100"
              >
                <FormSelect
                  :options="preference.options"
                  :bind-id="preference.get"
                  :get="preference.get"
                  :set="preference.set"
                  required
                />
              </FormGroup>
            </FormSection>
          </div>
        </FormSection>
        <!-- ===== BUSINESS ASSOCIATION ===== -->
        <FormSection
          v-if="showBusinessAccess"
          title="Add Payment Profile?"
        >
          <FormInput
            bind-id="accountTypeBusiness"
            type="checkbox"
            label="Yes"
            get="accountTypeBusiness"
            set="TOGGLE_ACCOUNT_TYPE_BUSINESS"
            class="w-100"
          />
        </FormSection>
        <!-- ===== PAYMENT PROFILE ===== -->
        <FormSection
          v-if="showPaymentProfile"
          title="Payment Profile"
          description="Explorers will have their profile associated with their business account. If the business account is not on the list, ask Watson for help."
        >
          <FormGroup
            for="businessAccountId"
            label="Business Account"
            class="w-100"
          >
            <FormSelect
              :options="businessAccountOptions"
              bind-id="businessAccountId"
              get="businessAccountId"
              set="SET_BUSINESS_ACCOUNT_ID"
            />
          </FormGroup>
        </FormSection>
        <!-- ===== REFERRAL ===== -->
        <FormSection
          v-if="showReferral"
          title="Referral, if applicable"
          description="The referring Explorer will get a credit equal to the cost of the new Explorer's plan. This new Explorer will get their first month free. There is no limit to the number of referrals one can make."
        >
          <FormGroup
            :state="referralState"
            invalid-feedback="Referrer email does not belong to an Explorer."
            valid-feedback="Success! Referral applied"
            for="referralEmail"
            label="Referring Explorer Email"
            class="w-100"
          >
            <FormInput
              :state="referralState"
              :readonly="referralState"
              :disabled="referralVerifyButtonDisabled"
              bind-id="referralEmail"
              get="referralEmail"
              set="UPDATE_REFERRAL_EMAIL"
              class="form-inline-input"
              @keyup.enter.native="verifyReferralEmail"
            />
            <b-button
              v-if="referralState"
              :disabled="referralVerifyButtonDisabled"
              variant="secondary"
              class="form-inline-button"
              @click="verifyReferralEmail"
            >
              Verify
            </b-button>
            <b-button
              v-if="referralState"
              variant="outline-danger"
              class="form-inline-button"
              @click="resetReferral"
            >
              Reset
            </b-button>
          </FormGroup>
          <small>Find using
            <span>
              <b-link
                :href="mgmtLink"
                target="_blank"
              >
                mgmt dashboard.
              </b-link>
            </span>
          </small>
        </FormSection>
        <!-- ===== BASIC INFO ===== -->
        <FormSection
          v-if="showBasicInfo"
          title="Basic Information"
        >
          <!-- FIRST & LAST NAME -->
          <div class="d-sm-flex justify-content-sm-between">
            <FormGroup
              for="firstName"
              label="First Name*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('First Name')"
                name="First Name"
                bind-id="firstName"
                data-recurly="first_name"
                invalid-feedback="First Name Required."
                invalid-feedback-id="first-name-invalid-feedback"
                get="firstName"
                set="UPDATE_FIRST_NAME"
                required
              />
            </FormGroup>
            <FormGroup
              for="lastName"
              label="Last Name*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Last Name')"
                name="Last Name"
                bind-id="lastName"
                data-recurly="last_name"
                invalid-feedback="Last Name Required."
                invalid-feedback-id="last-name-invalid-feedback"
                get="lastName"
                set="UPDATE_LAST_NAME"
                required
              />
            </FormGroup>
          </div>
          <!-- EMAIL -->
          <FormGroup
            for="email"
            label="Email Address"
            class="w-100"
          >
            <FormInput
              bind-id="email"
              get="email"
              set="UPDATE_EMAIL"
              placeholder="the_best_explorer@aira.io"
              invalid-feedback="Email Required."
              invalid-feedback-id="email-invalid-feedback"
              required
            />
          </FormGroup>
          <!-- HOW DID YOU HEAR ABOUT AIRA? -->
          <SignupLeadSurvey :readonly="referralActive" />
        </FormSection>
        <!-- ===== BILLING INFO ===== -->
        <FormSection
          v-if="showBillingInfo"
          title="Billing Information"
        >
          <!-- recurly script -->
          <div>
            <script2 src="https://js.recurly.com/v4/recurly.js" />
            <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
            <!-- eslint-disable -->
            <script2>
              $(document).ready(function () {
                recurly.configure({
                  publicKey: '{{publicKey}}',
                });
              });

              recurly.on('field:submit', function (event) {
                event.preventDefault();
              });

              $('form').on('submit', function (event) {
                event.preventDefault();
                var form = this;

                if ($('#isManualPayment').prop('checked')) {
                  $('#' + $('#buttonId').val()).click();
                  return;
                }

                recurly.token(form, function (err, token) {
                  if (err) {
                    console.log(err);
                    alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
                  } else {
                    $('#token').val(token.id);
                    $('#' + $('#buttonId').val()).click();
                  }
                });
              });

            </script2>
            <!-- eslint-enable -->
          </div>
          <!-- BILLING ADDRESS 1 -->
          <FormGroup
            for="address1"
            label="Address*"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Address')"
              name="Address"
              bind-id="address1"
              data-recurly="address1"
              invalid-feedback="Address Required."
              invalid-feedback-id="address1-invalid-feedback"
              get="address1"
              set="UPDATE_ADDRESS1"
              required
            />
          </FormGroup>
          <div class="d-sm-flex flex-wrap justify-content-sm-between">
            <!-- BILLING COUNTRY-->
            <FormGroup
              for="country"
              label="Country*"
              class="w-50-100"
            >
              <FormSelect
                :options="countryOptions"
                :state="null"
                name="country"
                bind-id="country"
                data-recurly="country"
                invalid-feedback="Country Required."
                invalid-feedback-id="country-invalid-feedback"
                get="country"
                set="UPDATE_COUNTRY"
                required
              />
            </FormGroup>
            <!-- BILLING STATE -->
            <FormGroup
              for="state"
              label="State/Province/Region*"
              class="w-50-100"
            >
              <FormSelect
                :options="stateOptions"
                :state="null"
                name="state"
                bind-id="state"
                data-recurly="state"
                invalid-feedback="State Required."
                invalid-feedback-id="state-invalid-feedback"
                get="state"
                set="UPDATE_STATE"
                required
              />
            </FormGroup>
            <!-- BILLING CITY -->
            <FormGroup
              for="city"
              label="City*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('City')"
                name="City"
                bind-id="city"
                data-recurly="city"
                invalid-feedback="City Required."
                invalid-feedback-id="city-invalid-feedback"
                get="city"
                set="UPDATE_CITY"
                required
              />
            </FormGroup>
            <!-- BILLING ZIP/POSTAL CODE -->
            <FormGroup
              for="zip"
              label="Zip or Postal Code*"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Zip')"
                name="Zip"
                bind-id="zip"
                data-recurly="postal_code"
                invalid-feedback="Zip or Postal Code Required."
                invalid-feedback-id="zip-invalid-feedback"
                get="zip"
                set="UPDATE_ZIP"
                required
              />
            </FormGroup>
          </div>
          <FormGroup
            v-if="showSameShippingBilling"
            for="sameShippingBilling"
            class="w-100"
          >
            <FormInput
              bind-id="sameShippingBilling"
              type="checkbox"
              label="Shipping Address is same as Billing Address"
              get="sameShippingBilling"
              set="TOGGLE_SAME_SHIPPING_BILLING"
              class="w-100"
            />
          </FormGroup>
        </FormSection>
        <!-- ===== SHIPPING INFO ===== -->
        <FormSection
          v-if="showShippingInfo"
          title="Shipping Information"
        >
          <!-- ADDRESS 1 -->
          <FormGroup
            for="shippingAddress1"
            label="Shipping Address 1"
            class="w-100"
          >
            <FormInput
              bind-id="shippingAddress1"
              required
              get="shippingAddress1"
              set="UPDATE_SHIPPING_ADDRESS1"
            />
          </FormGroup>
          <!-- ADDRESS 2 -->
          <FormGroup
            for="shippingAddress2"
            label="Shipping Address 2 (Optional)"
            class="w-100"
          >
            <FormInput
              bind-id="shippingAddress2"
              get="shippingAddress2"
              set="UPDATE_SHIPPING_ADDRESS2"
            />
          </FormGroup>
          <!-- CITY & STATE/REGION/PROVINCE -->
          <div class="d-sm-flex flex-wrap justify-content-sm-between">
            <FormGroup
              for="shippingCountry"
              label="Shipping Country"
              class="w-50-100"
            >
              <FormSelect
                :options="countryOptions"
                bind-id="shippingCountry"
                get="shippingCountry"
                set="UPDATE_SHIPPING_COUNTRY"
              />
            </FormGroup>
            <FormGroup
              v-if="shippingStateOptions.length"
              for="shippingState"
              label="Shipping State/Region/Province"
              class="w-50-100"
            >
              <FormSelect
                :options="shippingStateOptions"
                bind-id="shippingState"
                get="shippingState"
                set="UPDATE_SHIPPING_STATE"
                required
              />
            </FormGroup>
            <FormGroup
              for="shippingCity"
              label="Shipping City"
              class="w-50-100"
            >
              <FormInput
                bind-id="shippingCity"
                required
                get="shippingCity"
                set="UPDATE_SHIPPING_CITY"
              />
            </FormGroup>
            <!-- ZIP -->
            <FormGroup
              for="shippingZip"
              label="Shipping Zip/Postal Code"
              class="w-50-100"
            >
              <FormInput
                bind-id="shippingZip"
                required
                get="shippingZip"
                set="UPDATE_SHIPPING_ZIP"
              />
            </FormGroup>
          </div>
        </FormSection>
        <!-- ===== COUPONS ===== -->
        <FormSection
          v-if="showCoupon"
          title="Coupons, if applicable"
          description="To add more coupons, visit the newly created recurly account for this person after submitting the form. You'll be able to add more coupons before the subscription is activated."
        >
          <FormCoupon />
          <small>Find using
            <span>
              <b-link
                :href="recurlyLink"
                target="_blank"
              >
                recurly.
              </b-link>
            </span>
          </small>
        </FormSection>
        <!-- ===== RESELLERS ===== -->
        <FormSection
          v-if="showReseller"
          title="Resellers"
          description="Platform associates customer subscription with reseller. Customer signed up with code and remained customer for minimum length (3 months). Finance is able to see reseller sales breakdown for purposes of paying."
        >
          <FormGroup
            for="reseller"
            label="Reseller Code"
            class="w-100"
          >
            <FormInput
              bind-id="reseller"
              get="reseller"
              set="UPDATE_RESELLER"
            />
          </FormGroup>
        </FormSection>
        <!-- ===== PAYMENT INFO ===== -->
        <FormSection
          v-if="showPaymentInfo"
          title="Payment Information"
        >
          <!-- recurly script -->
          <div>
            <script2 src="https://js.recurly.com/v4/recurly.js" />
            <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
            <!-- eslint-disable -->
            <script2>
              $(document).ready(function () {
                recurly.configure({
                  publicKey: '{{publicKey}}',
                });
              });
            </script2>
            <!-- eslint-enable -->
          </div>
          <!-- MANUAL PAYMENT -->
          <FormGroup
            v-if="showManualPayment"
            for="isManualPayment"
            class="w-100"
          >
            <FormInput
              bind-id="isManualPayment"
              type="checkbox"
              label="Manual Payment"
              get="isManualPayment"
              set="TOGGLE_IS_MANUAL_PAYMENT"
              class="w-100"
            />
          </FormGroup>
          <!-- CREDIT CARD -->
          <FormGroup
            for="cc"
            label="Card Number*"
            class="w-100"
          >
            <div
              class="recurly-field"
              role="none"
              data-recurly="number"
            />
          </FormGroup>
          <!-- EXPIRATION & CVC -->
          <div
            class="d-flex justify-content-between flex-wrap"
          >
            <FormGroup
              for="expiryMonth"
              label="Expiration Month (MM)*"
              class="w-30-100"
            >
              <div
                class="recurly-field"
                role="none"
                data-recurly="month"
              />
            </FormGroup>
            <FormGroup
              for="expiryYear"
              label="Expiration Year (YYYY)*"
              class="w-30-100"
            >
              <div
                class="recurly-field"
                role="none"
                data-recurly="year"
              />
            </FormGroup>
            <FormGroup
              for="cvv"
              label="CVV*"
              class="w-30-100"
            >
              <FormInput
                :state="null"
                name="CVV"
                bind-id="cvv"
                data-recurly="cvv"
                invalid-feedback="CVV Required."
                invalid-feedback-id="cvv-invalid-feedback"
                get="cvv"
                set="UPDATE_CVV"
                required
              />
            </FormGroup>
          </div>
        </FormSection>
      </div>
      <input
        id="token"
        ref="token"
        type="hidden"
        name="recurly-token"
        data-recurly="token"
      >
      <input
        id="buttonId"
        type="hidden"
      >
      <!-- ===== ORDER SUMMARY ===== -->
      <b-button
        v-if="showSubmit && accountTypeIndividual"
        :disabled="previewButtonDisabled"
        class="signup-buttons w-75"
        size="lg"
        variant="secondary"
        type="submit"
        @click="setButtonId('orderSummaryButton')"
      >
        Get Order Summary
      </b-button>
      <b-btn
        id="orderSummaryButton"
        hidden
        @click="getOrderSummaryPreview()"
      />
      <SignupOrderSummary v-if="showOrderSummary" />
      <b-button
        v-if="debug || (phoneVerified && accountTypeIndividual)"
        :disabled="submitButtonDisabled"
        class="signup-buttons w-75"
        size="lg"
        variant="primary"
        type="submit"
        @click="setButtonId('submitButton')"
      >
        Submit
      </b-button>
      <b-btn
        id="submitButton"
        hidden
        @click="signup()"
      />
      <b-button
        v-if="debug || (phoneVerified && accountTypeBusiness && !accountTypeIndividual)"
        :disabled="submitButtonDisabled"
        class="signup-buttons w-75"
        size="lg"
        variant="primary"
        @click="signup()"
      >
        Submit
      </b-button>
    </b-form>
    <DevTools v-if="dev" />
  </div>
  <div v-else>
    <Error403 />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { validateEmail } from '../../utils/validation';
import * as role from '../../utils/permissions';
import hardware from '../../data/hardware.json';

import ValidationMixin from '../../mixin/ValidationMixin';
import OrderSummaryWatcherMixin from '../../mixin/OrderSummaryWatcherMixin';

import Error403 from '../Error/403.vue';
import DevTools from '../Tools/DevTools.vue';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import FormRadio from '../Form/FormRadio.vue';
import FormCoupon from '../Form/FormCoupon.vue';
import SignupLeadSurvey from '../Signup/SignupComponents/SignupLeadSurvey.vue';
import SignupOrderSummary from '../Signup/SignupComponents/SignupOrderSummary.vue';

export default {
  name: 'InternalSignup',
  components: {
    Error403,
    DevTools,
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    FormRadio,
    FormCoupon,
    SignupLeadSurvey,
    SignupOrderSummary,
  },
  mixins: [
    ValidationMixin,
    OrderSummaryWatcherMixin,
  ],
  data() {
    return {
      pageName: 'internalSignup',
      dev: process.env.NODE_ENV === 'development',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
      phoneVerifyButtonDisabled: false,
      phoneVerified: false,
      phoneVerifyStatus: null,
      referralVerifyButtonDisabled: false,
      referralActive: false,
      referralState: true,
      previewButtonDisabled: false,
      orderReviewed: false,
      submitButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'debug',
      'errors',
      'isGuest',
      'salesAttribution',
      'countryCodeOptions',
      'countryOptions',
      'operatingCountryOptions',
      'stateOptions',
      'shippingStateOptions',
      'accountTypeIndividual',
      'accountTypeBusiness',
      'subscriptions',
      'businessAccounts',
      'operatingCountry',
      'planOptions',
      'planCode',
      'plan',
      'userId',
      'firstName',
      'lastName',
      'countryCode',
      'phoneNumber',
      'phone',
      'noPhone',
      'email',
      'noEmail',
      'leadCode',
      'leadValue',
      'businessAccountId',
      'businessAccountOptions',
      'hardwareOptions',
      'hardwareSelected',
      'hardwareSelectedInfo',
      'horizonSize',
      'horizonTint',
      'sameShippingBilling',
      'address1',
      'country',
      'state',
      'city',
      'zip',
      'shippingAddress1',
      'shippingCountry',
      'shippingState',
      'shippingCity',
      'shippingZip',
      'referralEmail',
      'isManualPayment',
      'orderSummary',
    ]),
    showPhoneVerification() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'phoneValidation'));
    },
    showNoPhone() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'noPhone'));
    },
    showCountry() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'country') && this.phoneVerified);
    },
    showNewSubscription() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'accountType') && this.phoneVerified && !this.subscriptions);
    },
    showBusinessAccess() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'accountType') && this.phoneVerified);
    },
    showPaymentProfile() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'paymentProfile') && this.accountTypeBusiness && this.phoneVerified && this.businessAccountOptions);
    },
    showPlan() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'plan') && this.planOptions.length && this.accountTypeIndividual && this.phoneVerified);
    },
    showHardware() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'hardware') && this.accountTypeIndividual && this.planCode && this.hardwareOptions.length && this.phoneVerified);
    },
    showHardwarePreferences() {
      return this.debug || (this.showHardware && this.hardwarePreferences.length && this.hardwareSelected !== 'none');
    },
    showBasicInfo() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'basicInfo') && ((this.accountTypeIndividual && this.planCode) || this.accountTypeBusiness) && this.phoneVerified);
    },
    showBillingInfo() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'billingInfo') && this.accountTypeIndividual && this.planCode && this.phoneVerified);
    },
    showSameShippingBilling() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'shippingInfo') && this.showBillingInfo && (this.hardwareSelected !== 'none'));
    },
    showShippingInfo() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'shippingInfo') && this.showSameShippingBilling && !this.sameShippingBilling);
    },
    showReferral() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'referral') && this.accountTypeIndividual && this.planCode && this.phoneVerified);
    },
    showCoupon() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'coupon') && this.accountTypeIndividual && this.planCode && this.phoneVerified);
    },
    showReseller() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'reseller') && this.accountTypeIndividual && this.planCode && this.phoneVerified);
    },
    showPaymentInfo() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'paymentInfo') && this.accountTypeIndividual && this.planCode && this.phoneVerified);
    },
    showManualPayment() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'manualPayment'));
    },
    showOrderSummary() {
      return this.debug || (role.hasComponentAccess(this.pageName, 'orderSummary') && this.accountTypeIndividual && this.phoneVerified && this.planCode && this.orderSummary.length);
    },
    showSubmit() {
      return this.debug || (this.phoneVerified && (this.accountTypeBusiness || this.accountTypeIndividual));
    },
    hardwarePreferences() {
      if (!this.hardwareSelected || this.hardwareSelected === 'none') return [];
      const pref = hardware[this.hardwareSelected].preferences;
      if (!pref) return [];
      return hardware.preferences[pref];
    },
    userMgmtLink() {
      if (process.env.VUE_APP_ENV_NAME === 'prod') {
        return `${process.env.VUE_APP_MGMT_URL}/users/${this.userId}/subscription`;
      }
      return `${process.env.VUE_APP_MGMT_URL}/users/${this.userId}/subscription`;
    },
    mgmtLink() {
      return process.env.VUE_APP_MGMT_URL;
    },
    recurlyLink() {
      if (process.env.VUE_APP_ENV_NAME === 'prod') {
        return 'https://aira.recurly.com/coupons';
      }
      return 'https://aira-dev.recurly.com/coupons';
    },
  },
  watch: {
    noPhone() {
      this.phoneVerified = this.noPhone;
      if (this.noPhone) this.$store.commit('UPDATE_PHONE_NUMBER', '');
    },
    phone() {
      if (this.phoneVerified) {
        this.phoneVerified = false;
        this.phoneVerifyStatus = false;
        this.$store.dispatch('resetUserStatus');
      }
      if (this.noPhone) this.phoneVerified = true;
    },
    operatingCountry() {
      this.$store.dispatch('getInternalSignupPlans');
      this.$store.commit('UPDATE_COUNTRY', this.operatingCountry);
      this.$store.commit('UPDATE_SHIPPING_COUNTRY', this.operatingCountry);
    },
    planCode() {
      this.$store.dispatch('setPlan');
    },
    accountTypeBusiness() {
      if (this.accountTypeBusiness && !this.businessAccountOptions) this.$store.dispatch('getBusinessAccounts');
    },
  },
  mounted() {
    this.$store.dispatch('getInternalSignupPlans');
    if (this.phone) {
      this.$store.dispatch('verifyPhone').then((response) => {
        switch (response) {
          case 'guest':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          case 'new':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          default:
            this.phoneVerifyStatus = false;
            this.$store.commit('PUSH_ERRORS', ['Phone number is invalid']);
        }
        this.phoneVerifyButtonDisabled = false;
      });
    }
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    progress() { return { full: true }; },
    async verifyPhone() {
      if (this.phoneVerifyStatus) return;
      this.phoneVerifyButtonDisabled = true;
      // TODO throttle
      this.$store.commit('CLEAR_ERRORS');
      if (this.phoneNumber.length < 7) {
        this.phoneVerifyStatus = false;
        this.phoneVerifyButtonDisabled = false;
        return;
      }
      this.$store.dispatch('verifyPhone').then((response) => {
        switch (response) {
          case 'guest':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          case 'new':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          default:
            this.phoneVerifyStatus = false;
            this.$store.commit('PUSH_ERRORS', ['Phone number is invalid']);
        }
        this.phoneVerifyButtonDisabled = false;
      });
    },
    async verifyReferralEmail() {
      if (this.referralEmail === '') return;
      this.referralVerifyButtonDisabled = true;
      this.$store.dispatch('verifyReferralEmail', this.referralEmail).then((response) => {
        if (response) {
          this.referralState = true;
          this.referralActive = true;
          this.update();
        } else {
          this.referralState = false;
        }
        this.referralVerifyButtonDisabled = false;
      });
    },
    async resetReferral() {
      await this.$store.commit('UNSET_REFERRAL');
      await this.$store.commit('UPDATE_LEAD_CODE', '');
      await this.$store.commit('UPDATE_LEAD_VALUE_OPTION', '');
      this.referralVerifyButtonDisabled = false;
      this.referralState = true;
      this.referralActive = false;
      this.update();
    },
    async setButtonId(buttonId) {
      $('#buttonId').val(buttonId);
    },
    async getOrderSummaryPreview() {
      this.previewButtonDisabled = true;
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }

      this.$session.set('sales-attribution', this.salesAttribution);
      try {
        this.$store.dispatch('getOrderPreview').then(() => {
          this.previewButtonDisabled = false;
        });
      } catch (error) {
        console.error(error);
        this.previewButtonDisabled = false;
      }
    },
    async signup() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      // business
      if (this.accountTypeBusiness) {
        if (!this.businessAccountId) fieldErrors.push('Payment Profile');
      }
      // basic info
      if (!this.firstName) fieldErrors.push('First Name');
      if (this.noPhone) {
        if (!this.email) fieldErrors.push('Email');
      }
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (!this.leadCode) fieldErrors.push('How did you hear about Aira');
      if (this.leadCode && !this.leadValue) fieldErrors.push('Please specify on source about how did you hear about Aira');

      if (fieldErrors.length) {
        this.$store.commit('PUSH_ERRORS', fieldErrors);
        return;
      }

      this.submitButtonDisabled = true;
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }
      this.$session.set('sales-attribution', this.salesAttribution);
      this.$store.dispatch('doSignup').then((response) => {
        if (response) {
          // eslint-disable-next-line
          if (confirm('Great! The new Explorer is successfully signed up. Remind them to download the app and sign in as soon as possible to start working with agents right away.')) {
            this.$store.commit('SET_NEED_REFRESH');
            this.$router.push('/internal');
          } else {
            this.$store.commit('SET_NEED_REFRESH');
            this.$router.push('/internal');
          }
        }
        this.submitButtonDisabled = false;
      });
    },
  },

};
</script>

<style lang="scss">
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#internal-signup {
  .form {
    padding: 40px 20px 0px 20px;
  }
  #plan-info {
    padding: 10px 35px;
  }
  #phone-verify-button {
    margin-top: 21px;
  }
  .signup-buttons {
    margin-bottom: 20px;
  }
  .emphasize {
    font-size: x-large;
    font-weight: bold;
    color: red;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
  }
}
</style>
